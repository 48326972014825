




























































import { Espesor ,ObtenerSelectEspesor} from '@/entidades/Maestro/Ktb/Espesor';
import {TipoMadera,ObtenerSelectTipoMadera} from '@/entidades/Maestro/Ktb/TipoMadera';
import {Material,GuardarMaterial,EditarMaterial} from '@/entidades/Maestro/Ktb/Material';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Validate, Validations} from 'vuelidate-property-decorators';
import {required,numeric,minValue} from 'vuelidate/lib/validators'
import { Action } from 'vuex-class';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component
export default class FormularioMaterial extends Vue
{

  @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
  @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
  @Prop({required:true,type:Number})
  action!:number;
  @Prop({required:false})
  modelo!:Material;

  descripcion?:string="";
  espesorId?:number =0;
  tipoMaderaId?:number =0;
  mg1?:string ="";
  mg2?:string ="";
  materialId?:number =0;

  listadoEspesor:Array<Espesor> =[];
  listadoTipoMadera:Array<TipoMadera> = [];

  //Configuracion de validacion
  @Validations()
     validations = {
         descripcion : {required},
         espesorId : {required, numeric,minValue:minValue(1)},
         tipoMaderaId : {required, numeric,minValue:minValue(1)}
     }

 async guardar()
 {
     try
     {
         this.changeLoading(new Loading(true,"Registrando informacion"));
         let response = new ResponseGenerico(false,"");
         let material:Material = new Material(this.materialId,this.tipoMaderaId,this.espesorId,this.descripcion,this.mg1,this.mg2,true);
         console.log(material);
         response = this.action == 1 ?  await GuardarMaterial(material) : await EditarMaterial(material);
         if(response.isSuccess == true)
         {
             this.$emit('limpiar');
         }
         else
         {
            this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
         }
     }
     catch(error)
     {
         //@ts-ignore
        this.changeAlerta(new Alerta(error,true,"danger",3000));
     }
     finally
     {
        this.changeLoading(new Loading(false,""));
     }
    
 }

 async ObtenerEspesor()
 {
     try
     {
         let rsp = await ObtenerSelectEspesor();
         if(rsp.isSuccess == true)
         {
             this.listadoEspesor = rsp.isResult;
         }
     }
     catch(error)
     {
         console.log(error);
     }
 }

  async ObtenerTipoMadera()
 {
     try
     {
         let rsp = await ObtenerSelectTipoMadera();
         if(rsp.isSuccess == true)
         {
             this.listadoTipoMadera = rsp.isResult;
         }
     }
     catch(error)
     {
         console.log(error);
     }
 }


 get mensaje()
 {
     if(this.action == 1)
        return {'titulo' : "REGISTRO DE MATERIAL" , 'boton' : "GUARDAR"};
    else
        return {'titulo' : "EDICION DE MATERIAL" , 'boton' : "EDITAR"};
 }

  get mensajeSelectEspesor()
    {
        const errors: string[] = [];
        if(!this.$v.espesorId.$dirty) return errors;
        !this.$v.espesorId.minValue && errors.push("Valor minimo es 1");
        !this.$v.espesorId.required && errors.push("El Campo es requerido");
        return errors;
       
    }

     get mensajeSelectTipoMadera()
    {
        const errors: string[] = [];
        if(!this.$v.tipoMaderaId.$dirty) return errors;
        !this.$v.tipoMaderaId.minValue && errors.push("Valor minimo es 1");
        !this.$v.tipoMaderaId.required && errors.push("El Campo es requerido");
        return errors;
       
    }

 mounted() 
 {
    if(this.modelo!=undefined)
    {
        this.materialId = this.modelo.materialId;
        this.descripcion = this.modelo.materialFinal;
        this.espesorId = this.modelo.espesorId;
        this.tipoMaderaId = this.modelo.tipoMaderaId;
        this.mg1 = this.modelo.mG1;
        this.mg2 = this.modelo.mG2;
    }
    //Consultar los demas datos informacion   
    this.ObtenerEspesor();
    this.ObtenerTipoMadera();
 }

}
