import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Material
{
    materialId?:number =0;
    tipoMaderaId?:number =0;
    materialFinal?:string ="";
    espesorId?:number =0;
    mG1?:string ="";
    mG2?:string ="";
    estado?:boolean = false;
    tipoMaderaName?:string ="";
    espesorName?:string ="";

    constructor(_materialId?:number,_tipoMaderaId?:number,_espesorId?:number,_materialFinal?:string,_mg1?:string,_mg2?:string,_estado?:boolean)
    {
        this.materialId = _materialId;
        this.tipoMaderaId = _tipoMaderaId;
        this.espesorId = _espesorId;
        this.materialFinal = _materialFinal;
        this.mG1 = _mg1;
        this.mG2 = _mg2;
        this.estado = _estado;
    }
}

async function  GuardarMaterial(material:Material):Promise<ResponseGenerico>
{
    return await Save<Material>("material/Guardar",material,mainAxios);
} 

async function  EditarMaterial(material:Material):Promise<ResponseGenerico>
{
    return await Editar<Material>("material/Actualizar",material,mainAxios);
} 

async function  EditarEstadoMaterial(material:Material):Promise<ResponseGenerico>
{
    return await Editar<Material>("material/CambiarEstado",material,mainAxios);
} 

async function ObtenerMaterial()
{
    return await Obtener<Material>("material/Obtener",mainAxios);
}


async function ObtenerSelectMaterial()
{
    return await Obtener<Material>("material/ObtenerSelect",mainAxios);
}

export 
{
    Material,
    GuardarMaterial,
    EditarMaterial,
    EditarEstadoMaterial,
    ObtenerMaterial,
    ObtenerSelectMaterial
}